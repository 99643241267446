import React, { useState, useEffect } from 'react';
import { message, Modal, Progress, Upload } from 'components/shared/AntComponent';
import { Form } from 'components/shared/FormComponent';
import { useDispatch, useSelector } from 'components/shared/CompVariables';
import { uploadResultDocument } from 'services/actions/pipeline/pipelineAction';
import { WarningTwoTone } from 'components/shared/AntIcons';

const { Dragger } = Upload;

interface ChildProps {
    openModal: boolean;
    closeModal: () => void;
    rowData: any;
}
const UploadDocument: React.FC<ChildProps> = ({ openModal, closeModal, rowData }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { loading1, success1, error1 } = useSelector((state: any) => state.pipeline);
    const { resultDocProgress } = useSelector((state: any) => state.upload);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success1 : false;
    const [showErrormsg, setShowErrormsg] = useState(false);
    const errormsg = showErrormsg ? error1 : false;

    const submitForm = async () => {
        const value = form.getFieldsValue();
        const formData = new FormData();
        try {
            await form.validateFields();
            formData.append('File', value.document?.fileList[0]?.originFileObj);
            const inputJson = {
                requestid: rowData?.id,
                userid: Number(sessionStorage.getItem('userid')),
                doctype: 'result',
            };
            formData.append('InputJson', JSON.stringify(inputJson));
            dispatch(uploadResultDocument(formData) as any);
            setShowErrormsg(true);
            setShowSuccessmsg(true);
        } catch (error: any) {
            console.log('Failed', error);
        }
    };

    useEffect(() => {
        if (successmsg) {
            setShowSuccessmsg(false);
            closeModal();
            message.success('Result document uploaded successfully');
            form.resetFields();
        }
        if (errormsg) {
            if (error1?.data) {
                message.error(error1?.data);
            } else {
                message.error("Result document couldn't be uploaded");
            }
            setShowErrormsg(false);
        }
    }, [successmsg, errormsg]);

    return (
        <div>
            <Modal open={openModal} onCancel={() => closeModal()} onOk={submitForm} confirmLoading={loading1} title="Upload Result Document" okText="Upload"
            cancelButtonProps={{
                style: { backgroundColor: '#ff4242', color: 'white' }
            }}
            >
                <div className="bg-aliceblue border d-flex ps-3 pe-2 py-2 mb-3">
                    <div className="col">
                        <h6>Request No</h6>
                        <p>{rowData?.encoded_RequestNumber}</p>
                    </div>
                    <div className="col">
                        <h6>Patient Name</h6>
                        <p>{rowData?.patient_name}</p>
                    </div>
                    <div className="col">
                        <h6>Account Name</h6>
                        <p>{rowData?.account_name}</p>
                    </div>
                </div>
                <Form form={form} layout="vertical">
                    <Form.Item name="document" valuePropName="document" rules={[{ required: true, message: 'This field is required' }]}>
                        <Dragger
                            name="file"
                            multiple={false}
                            maxCount={1}
                            beforeUpload={() => false}
                            listType="picture-card"
                            accept=".pdf"
                            onChange={(info: any) => {
                                form.setFieldValue('document', info);
                            }}
                        >
                            <p className="ant-upload-drag-icon"></p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">Upload PDF file here</p>
                        </Dragger>
                    </Form.Item>
                    {loading1 ? (
                        <Progress size={[485, 20]} percent={resultDocProgress} percentPosition={{ align: 'center', type: 'inner' }} strokeColor={{ from: '#108ee9', to: '#87d068' }} />
                    ) : (
                        ''
                    )}
                    {rowData?.edfprocessing_flag ? (
                        <div className="fs-16 text-warn">
                            <WarningTwoTone className="text-warning fs-16" twoToneColor="#ff9966" /> Job processing is in queue
                        </div>
                    ) : rowData?.edfcomplete_flag ? (
                        <div className="fs-16 text-warn">
                            <WarningTwoTone className="text-warning fs-16" twoToneColor="#ff9966" /> Job request is completed. If you upload result manually, this will overwrite the datahub
                            result.
                        </div>
                    ) : (
                        ''
                    )}
                </Form>
            </Modal>
        </div>
    );
};

export default UploadDocument;
