import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'components/shared/CompVariables';
import { InputNumber, Radio } from 'components/shared/FormComponent';
import { Button } from 'components/shared/ButtonComponent';
import { message, Spin } from 'components/shared/AntComponent';
import { getRequestAssMarkers, saveInterpretationMarker } from 'services/actions/pipeline/stepwizardAction';
import { useLocation } from 'react-router-dom';

const Interpretation: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { assMarkerInfo, success3, loading3, error3, loading } = useSelector((state: any) => state.wizard);
    const [markerData, setMarkerData] = useState([]);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success3 : false;
    const [showErrormsg, setShowErrormsg] = useState(false);
    const errormsg = showErrormsg ? error3 : false;

    useEffect(() => {
        if (assMarkerInfo?.data && assMarkerInfo?.data?.length > 0) {
            setMarkerData(assMarkerInfo?.data);
        }
    }, [assMarkerInfo]);

    const changeInputField = (id: any, type: string, e: any, field: string) => {
        if (field === 'text') {
            if (type === 'open') {
                setMarkerData((current: any) =>
                    current.map((obj: any) => {
                        if (obj.id === id) {
                            return { ...obj, eyeopen: e };
                        }

                        return obj;
                    }),
                );
            } else {
                setMarkerData((current: any) =>
                    current.map((obj: any) => {
                        if (obj.id === id) {
                            return { ...obj, eyeclosed: e };
                        }

                        return obj;
                    }),
                );
            }
        } else {
            if (e.target.value === 'open') {
                setMarkerData((current: any) =>
                    current.map((obj: any) => {
                        if (obj.id === id) {
                            return { ...obj, eyeopen: e.target.checked?.toString(), eyeclosed: 'false' };
                        }

                        return obj;
                    }),
                );
            } else {
                setMarkerData((current: any) =>
                    current.map((obj: any) => {
                        if (obj.id === id) {
                            return { ...obj, eyeclosed: e.target.checked?.toString(), eyeopen: 'false' };
                        }

                        return obj;
                    }),
                );
            }
        }
    };

    const handleRadioChangeOpened = (id, val) => (e) => {
        if (val == 'open') {
            if (e.target.checked === true) {
                setMarkerData((current: any) =>
                    current.map((obj) => {
                        if (obj.id === id) {
                            return { ...obj, eyeclosed: 'false', eyeopen: 'true' };
                        }
                        return obj;
                    })
                );
            } 
        } else {
            if (e.target.checked === true) {
                setMarkerData((current: any) =>
                    current.map((obj) => {
                        if (obj.id === id) {
                            return { ...obj, eyeclosed: 'true', eyeopen: 'false' };
                        }
                        return obj;
                    })
                );
            }
        }
    };

    const submitForm = () => {
        const markerArr: any = [];
        markerData?.forEach((items: any) => {
            markerArr.push({
                id: items.id,
                checked: 'true',
                eyeopen: items.eyeopen || '',
                eyeclosed: items.eyeclosed || '',
            });
        });
        dispatch(saveInterpretationMarker(markerArr) as any);
        setShowErrormsg(true);
        setShowSuccessmsg(true);
    };
    function getMarkers() {
        const inputJson = {
            sr_interpretation: location.state?.id,
        };
        dispatch(getRequestAssMarkers(inputJson) as any);
    }
    useEffect(() => {
        if (successmsg) {
            setShowSuccessmsg(false);
            message.success('Interpretation saved successfully');
            getMarkers();
        }
        if (errormsg) {
            if (error3?.data) {
                message.error(error3?.data);
            } else {
                message.error("Interpretation couldn't be saved");
            }
            setShowErrormsg(false);
        }
    }, [successmsg, errormsg]);

    return (
        <div className="h-100">
            <div className="bg-white p-3 h-100">
                <h6 className="fs-17">Interpretation Markers</h6>
                <Spin spinning={loading}>
                    <div className="mt-3">
                        <div className="row m-0 bg-light p-3">
                            {markerData?.map((item: any, index: any) => {
                                return (
                                    <div className={`col-md-6 mb-4 ${index % 2 === 0 ? '' : ''}`} key={item.id}>
                                        <label className="fs-16 fw-bold">{item.markername}</label>
                                        <div className="mt-2">
                                            {item.mfieldtype?.toLowerCase() === 'textbox' || item.mfieldtype?.toLowerCase() === 'text' ? (
                                                <div className="d-flex bg-white py-1 px-3">
                                                    {item.EC_isactive ? (
                                                        <div className="col-md-6">
                                                            <div className="d-flex">
                                                                <div className="fs-15">Eye Closed</div>
                                                                <div className="col-auto ms-auto text-danger fs-12 my-auto">(Expected 8.0-12.0)</div>
                                                            </div>
                                                            <InputNumber
                                                                className="w-100"
                                                                defaultValue={item.eyeclosed}
                                                                onChange={(e: any) => changeInputField(item.id, 'open', e, 'text')}
                                                            />
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {item.EO_isactive ? (
                                                        <div className="col ms-2">
                                                            <div className="d-flex">
                                                                <div className="fs-15">Eye Open</div>
                                                                <div className="col-auto ms-auto text-danger fs-12 my-auto">(Expected 8.0-12.0)</div>
                                                            </div>
                                                            <InputNumber
                                                                className="w-100"
                                                                defaultValue={item.eyeopen}
                                                                onChange={(e: any) => changeInputField(item.id, 'closed', e, 'text')}
                                                            />
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            ) : (
                                                // <Radio.Group
                                                //     className="bg-white px-3 py-4 w-100"
                                                //     value={item.eyeopen == 'true' ? 'eyeopen' : item.eyeclosed == 'true' ? 'eyeclosed' : ''}
                                                //     defaultValue={item.eyeopen == 'true' ? 'eyeopen' : item.eyeclosed == 'true' ? 'eyeclosed' : ''}
                                                //     onChange={(e: any) => changeInputField(item.id, '', e, 'radio')}
                                                // >
                                                //     <Radio value="eyeclosed">Eye Closed </Radio>
                                                //     <Radio value="eyeopen">Eye Open</Radio>
                                                // </Radio.Group>
                                                <Radio.Group className=" bg-white px-3 py-4 w-100 d-flex" value={item.eyeopen == 'true' ? 'eyeopen' : item.eyeclosed == 'true' ? 'eyeclosed' : null}>
                                                    {item.EO_isactive == true ? (
                                                        <Radio
                                                            value="eyeopen"
                                                            disabled={location.state.archive}
                                                            defaultChecked={item?.eyeopen == 'true' ? true : false}
                                                            onChange={handleRadioChangeOpened(item.id, 'open')}
                                                            className="w-50"
                                                        >
                                                            Eye Opened
                                                        </Radio>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {item.EC_isactive == true ? (
                                                        <Radio
                                                            value="eyeclosed"
                                                            disabled={location.state.archive}
                                                            defaultChecked={item?.eyeclosed == 'true' ? true : false}
                                                            onChange={handleRadioChangeOpened(item.id, 'close')}
                                                        >
                                                            Eye Closed
                                                        </Radio>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Radio.Group>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="mt-3 text-end">
                            <Button type="primary" loading={loading3} onClick={() => submitForm()}>
                                Save
                            </Button>
                        </div>
                    </div>
                </Spin>
            </div>
        </div>
    );
};

export default Interpretation;
