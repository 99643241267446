import React from 'react';
import { Modal, Progress } from 'components/shared/AntComponent';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

interface ChildProps {
    openModal: boolean;
    closeModal: () => void;
    invoiceUrl: any;
    statementUrl: any;
    detail: any;
}

const ViewInvoice: React.FC<ChildProps> = ({ openModal, closeModal, invoiceUrl, statementUrl,detail }) => {
    const docs = [
        {
            uri: invoiceUrl,
            fileName: `${detail ? detail?.invoice_number : 'Datahub_invoice'}.pdf`,
        },
        {
            uri: statementUrl,
            fileName: `${detail ? detail?.invoice_number : 'Datahub_invoice'}.pdf`,
        },
    ];

    return (
        <div>
            <Modal className="invoice-modal" title="Invoice Preview" width={600} height={500} open={openModal} onCancel={closeModal}
            cancelButtonProps={{
                style: { backgroundColor: '#ff4242', color: 'white' }
            }}
            cancelText="Close"
            okButtonProps={{
                style: { display: 'none' }, 
            }}
            >
                <div className='doc-overflow'>
                    <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
                </div>
            </Modal>
        </div>
    );
};

export default ViewInvoice;
